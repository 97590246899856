import { combineReducers } from "redux";

import { createReducer } from "../../utils";
import { SUCCESS } from "../../utils/types/create-constants.types";
import {
  getSourceCurrenciesTypes,
  getDestinationCurrenciesTypes,
} from "../transfer/transfer.constants";
import environment from "../../../../environment";
import organizationsConfig from "../../../../../src/organizations.conf.json";

import {
  getOrganizationDataTypes,
  getMobileConfigTypes,
  getRecipientRelationshipsTypes,
  getRegistrationFieldsTypes,
  getPurposesOfTransferTypes,
  OrganizationStoreConstants,
  getSourcesOfFundsTypes,
  getDocTypes,
  getIdDocTypes,
  getListOfAgentsTypes,
  organizationAddresses,
  organizationSupportInfo,
} from "./organization.constants";
import {
  IOrganization,
  IOrganizationState,
  IMobileConfiguration,
  IMobileConfigurationCountries,
  ITransferDataByCountry,
  IExtraOptions,
  PaymentProcessors,
} from "./organization.types";

const {
  ORGANIZATION_DATA,
  MOBILE_CONFIGURATION,
  REGISTRATION_FIELDS,
  EXTRA_OPTIONS,
  TRANSFER_DATA_BY_COUNTRY,
  SOURCE_CURRENCIES,
  DESTINATION_CURRENCIES,
  RECIPIENT_RELATIONSHIPS,
  PURPOSES_OF_TRANSFER,
  SOURCES_OF_FUNDS,
  PAYMENT_PROCESSORS_BY_COUNTRY,
  DOC_TYPES,
  ID_DOC_TYPES,
  ORGANIZATION_AGENTS,
  SUPPORT_INFO,
} = OrganizationStoreConstants;

/* parse default countries config into simple structure for straightforward access
  TODO: change endpoint to get proper structure at once
*/
const prepareTransferDataByCountry = (
  config: IMobileConfigurationCountries[],
): ITransferDataByCountry => {
  return config.reduce(
    (acc, item) => ({
      ...acc,
      [item.country]: item.payoutTypes.reduce(
        (accum, el) => ({ ...accum, [el.payoutType]: el }),
        {},
      ),
    }),
    {},
  );
};

export const getOrganizationDataReducer = createReducer<IOrganization | {}>(
  {
    [getOrganizationDataTypes[SUCCESS]]: (_, { payload }) => ({
      ...payload, // TODO: Get user age from API
      organizationAddresses,
      youngestUserAge: 16,
    }),
  },
  {},
);

const getRegistrationFieldsReducer = createReducer<any[]>(
  {
    [getRegistrationFieldsTypes[SUCCESS]]: (state, { payload }) => ({
      ...state,
      ...payload.fields,
    }),
  },
  [],
);

export const getMobileConfigurationReducer = createReducer<
  IMobileConfiguration | {}
>(
  {
    [getMobileConfigTypes[SUCCESS]]: (_, { payload }) => {
      const data = { ...payload };
      if (data.settings) {
        data.settings.isHomePageAvailable =
          environment.organizationId === organizationsConfig.unitylink.id; // TODO: remove mock
      } else {
        data.settings = {
          isHomePageAvailable:
            environment.organizationId === organizationsConfig.unitylink.id,
        };
      }

      data.settings.calculatorDefaultValues = {
        sourceCountry: "GB",
        destinationCountry: "GH",
        payoutType: "MOBILE_TOPUP",
      };
      data.settings[SUPPORT_INFO] = organizationSupportInfo;
      data.settings.cashPickupLocations.GH[0].locations = [
        "Access Bank",
        "Assurance Savings and Loans Ltd",
        "Agricultural Development Bank",
        "ARB Apex Bank Ltd",
        "Bestpoint Savings & Loans",
        "Consolidated Bank Ghana Limited",
        "Direct Savings & Loans",
        "Ecobank Ghana Ltd",
        "Fidelity Bank Limited",
        "First Atlantic Bank Ghana Ltd",
        "GCB Bank Limited",
        "Global Access Savings and Loans",
        "Golden Savings and Loans",
        "Guaranty Trust Bank Ghana Limited",
        "OmniBank Ghana Limited",
        "Opportunity International Savings and Loans",
        "Pan-African Savings And Loans Company Limited",
        "Republic Bank Ghana Limited",
        "Universal Merchant Bank Ltd",
        "Utrak Savings & Loans Company Limited",
        "Zenith Bank Ghana Limited",
      ];

      data[PAYMENT_PROCESSORS_BY_COUNTRY] = [
        { country: "FR", processor: { name: PaymentProcessors.CHECKOUT } },
        {
          country: "GB",
          processor: {
            name: PaymentProcessors.SAGEPAY,
          },
        },
      ];

      data[TRANSFER_DATA_BY_COUNTRY] = prepareTransferDataByCountry(
        payload.countries,
      );

      return data;
    },
    [getSourceCurrenciesTypes[SUCCESS]]: (state, { payload }) => ({
      ...state,
      [SOURCE_CURRENCIES]: payload.currencies,
    }),
    [getDestinationCurrenciesTypes[SUCCESS]]: (state, { payload }) => ({
      ...state,
      [DESTINATION_CURRENCIES]: payload.currencies,
    }),
  },
  {},
);

export const getOrganizationExtraOptionsReducer = createReducer<
  IExtraOptions | {}
>(
  {
    [getRecipientRelationshipsTypes[SUCCESS]]: (state, { payload }) => ({
      ...state,
      [RECIPIENT_RELATIONSHIPS]: payload.relationships,
    }),
    [getSourcesOfFundsTypes[SUCCESS]]: (state, { payload }) => ({
      ...state,
      [SOURCES_OF_FUNDS]: payload.sourcesOfFunds,
    }),
    [getPurposesOfTransferTypes[SUCCESS]]: (state, { payload }) => ({
      ...state,
      [PURPOSES_OF_TRANSFER]: payload.transferPurposes,
    }),
    [getDocTypes[SUCCESS]]: (state, { payload }) => ({
      ...state,
      [DOC_TYPES]: payload.types,
    }),
    [getIdDocTypes[SUCCESS]]: (state, { payload }) => ({
      ...state,
      [ID_DOC_TYPES]: payload.types,
    }),
  },
  {
    [DOC_TYPES]: [
      "ID",
      "ID_BACK_SIDE",
      "PROOF_OF_ADDRESS",
      "SOURCE_OF_FUNDS",
      "PROOF_OF_OCCUPATION",
    ],
    [ID_DOC_TYPES]: ["DRIVING_LICENCE", "UK_ID", "EU_ID", "PASSPORT", "OTHER"],
  },
);

export const getOrganizationAgentsReducer = createReducer<any[]>(
  {
    [getListOfAgentsTypes[SUCCESS]]: (state, { payload }) => payload.agents,
  },
  [],
);

export default combineReducers<IOrganizationState>({
  [ORGANIZATION_DATA]: getOrganizationDataReducer,
  [MOBILE_CONFIGURATION]: getMobileConfigurationReducer,
  [REGISTRATION_FIELDS]: getRegistrationFieldsReducer,
  [EXTRA_OPTIONS]: getOrganizationExtraOptionsReducer,
  [ORGANIZATION_AGENTS]: getOrganizationAgentsReducer,
});
