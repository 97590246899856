import { get, difference } from "lodash";
import { createSelector } from "reselect";
import { result } from "lodash-es";

import { IAppState } from "../../types";
import {
  complianceRulesSelector,
  complianceDocsSelector,
} from "../transfer/transfer.selectors";
import { requiredDocumentsSelector } from "../organization/organization.selectors";

import { UserStoreConstants } from "./user.constants";
import { IUserDoc } from "./user.types";

const {
  USER_CONTACT_OPTIONS,
  AUTOCOMPLETE_ADDRESSES,
  USER_TRANSACTIONS,
  USER_CARDS,
} = UserStoreConstants;

export const userDataSelector = ({ user: { userData } }: IAppState) => userData;
export const updateIdDataPanelDisplayedSelector = ({
  user: { updateIdDataPanelDisplayed },
}: IAppState) => updateIdDataPanelDisplayed;
export const getUserDocumentCheckIdReportSelector = ({
  user: { getUserDocumentCheckIdReport },
}: IAppState) => getUserDocumentCheckIdReport;
export const userTwoFAEnabledSelector = ({
  user: { twoFAEnabled },
}: IAppState) => twoFAEnabled;
export const userTwoFADataSelector = ({ user: { userTwoFAData } }: IAppState) =>
  userTwoFAData;
export const userSignupReferralCodeSelector = ({
  user: { signupReferralCode },
}: IAppState) => signupReferralCode;

export const creditCardFormSelector = ({
  user: { creditCardForm },
}: IAppState) => creditCardForm;

export const signupDocsSelector = ({ user: { signupDocuments } }: IAppState) =>
  signupDocuments;

export const requestErrorSelector = ({ user: { requestError } }: IAppState) =>
  requestError;

export const userCountrySelector = ({ user: { userData } }: IAppState) =>
  get(userData, "country");

export const userIdSelector = ({ user: { userData } }: IAppState) =>
  get(userData, "userId", "");

export const userDocumentsSelector = ({ user: { userDocuments } }: IAppState) =>
  userDocuments;

export const userDocTypesSelector = ({ user: { userDocuments } }: IAppState) =>
  userDocuments.map((item: IUserDoc) => {
    if (item.documentSubType === "MAIN") {
      get(item, "documentType");
    }
  });

export const isUserHasRequiredComplianceDocumentsSelector = createSelector(
  userDocumentsSelector,
  (state: IAppState) => complianceDocsSelector(state),
  (userDocuments: IUserDoc[], requiredDocuments: string[]) => {
    const userDocTypesList = userDocuments.map((item: IUserDoc) =>
      get(item, "documentType"),
    );

    return requiredDocuments.every(
      (doc: any) =>
        userDocTypesList.includes(doc) ||
        userDocTypesList.includes(doc.documentType),
    );
  },
);

export const isUserHasRequiredDocumentsSelector = createSelector(
  userDocumentsSelector,
  userDataSelector,
  (state: IAppState) => requiredDocumentsSelector(state),
  (userDocuments: IUserDoc[], userData: any, requiredDocuments: string[]) => {
    const userDocTypesList = userDocuments.map((item: IUserDoc) =>
      get(item, "documentType"),
    );

    if (userData.idType === "PASSPORT") {
      requiredDocuments = requiredDocuments.filter(e => e !== "ID_BACK_SIDE");
    }

    const diff = difference(requiredDocuments, userDocTypesList);
    return diff.length === 0;
  },
);

export const verficationDocumentsSelector = createSelector(
  userDocumentsSelector,
  state => complianceRulesSelector(state),
  state => requiredDocumentsSelector(state),
  (userDocuments = [], complianceRule, requiredDocs) => {
    const reqDocuments = get(complianceRule, "requiredDocuments", requiredDocs);
    const resultDocs = reqDocuments.map((type: any) => {
      const doc = userDocuments.find(
        ({ documentType }: any) =>
          documentType === type || documentType === type.documentType,
      );

      if (doc) {
        return {
          preview: get(doc, "preview"),
          documentType: type,
        };
      }
      return { preview: "", documentType: type };
    });
    const rDocs = resultDocs.filter(
      (rd: any) =>
        !userDocuments.some(
          (us: any) =>
            us.documentType === rd.documentType ||
            us.documentType === rd.documentType.documentType,
        ),
    );
    return rDocs;
  },
);

export const userContactOptionsSelector = ({ user }: IAppState) =>
  get(user, USER_CONTACT_OPTIONS);

export const autocompleteOptionsSelector = ({ user }: IAppState) =>
  get(user, AUTOCOMPLETE_ADDRESSES);

export const userTransactionsInfoSelector = ({ user }: IAppState) =>
  get(user, USER_TRANSACTIONS);

export const userCardsSelector = ({ user }: IAppState) => get(user, USER_CARDS);

export const userTransactionsSelector = createSelector(
  userTransactionsInfoSelector,
  userTransactions => get(userTransactions, "transactionsList", []),
);

export const latestTransactionSelector = createSelector(
  userTransactionsInfoSelector,
  userTransactions => get(userTransactions, "latestTransaction", {}),
);

export const selectRejectionFlow = ({ user: { rejectionFlow } }: IAppState) =>
  rejectionFlow;
