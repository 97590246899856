import { createConstants } from "../../utils";
import environment from "../../../../environment";
import { moneyTransferRoutes } from "../../constants";
export const SET_TRANSFER_SOURCE_COUNTRY = "SET_TRANSFER_SOURCE_COUNTRY";
export const SET_TRANSFER_DESTINATION_COUNTRY =
  "SET_TRANSFER_DESTINATION_COUNTRY";
export const SET_TRANSFER_PAYOUT_TYPE = "SET_TRANSFER_PAYOUT_TYPE";
export const SET_TRANSFER_SOURCE_AMOUNT = "SET_TRANSFER_SOURCE_AMOUNT";
export const SET_TRANSFER_DESTINATION_AMOUNT =
  "SET_TRANSFER_DESTINATION_AMOUNT";
export const SET_TRANSFER_CURRENCY = "SET_TRANSFER_CURRENCY";
export const SET_REVERSE_CALCULATION = "SET_REVERSE_CALCULATION";
export const SET_TRANSFER_PRICE = "SET_TRANSFER_PRICE";
export const RESET_PRICE_RESPONSE = "RESET_PRICE_RESPONSE";
export const RESET_PRICE_RESPONSE_ERROR = "RESET_PRICE_RESPONSE_ERROR";
export const RESET_TRANSFER_DETAILS = "RESET_TRANSFER_DETAILS";
export const SET_TRANSFER_RECIPIENT = "SET_TRANSFER_RECIPIENT";
export const SET_TRANSFER_RECIPIENT_PHONE = "SET_TRANSFER_RECIPIENT_PHONE";
export const SET_TRANSFER_RECIPIENT_BANK_ACCOUNT =
  "SET_TRANSFER_RECIPIENT_BANK_ACCOUNT";
export const SET_PURPOSE_OF_TRANSFER = "SET_PURPOSE_OF_TRANSFER";
export const SET_SOURCE_OF_FUNDS = "SET_SOURCE_OF_FUNDS";
export const SET_TRANSFER_CARD_NUMBER = "SET_TRANSFER_CARD_NUMBER";
export const SET_PICKUP_LOCATION = "SET_PICKUP_LOCATION";
export const SET_BANK_EXTERNAL_CODE = "SET_BANK_EXTERNAL_CODE";
export const SET_MOBILE_OPERATOR = "SET_MOBILE_OPERATOR";
export const SET_FUNDING_SOURCE = "SET_FUNDING_SOURCE";
export const SET_CREDIT_CARD_DETAILS = "SET_CREDIT_CARD_DETAILS";
export const SET_SECURE_3D_INFO = "SET_CREDIT_CARD_DETAILS";
export const SET_CASH_CODE = "SET_CASH_CODE";
export const SET_IDEAL_BANK_NAME = "SET_IDEAL_BANK_NAME";
export const SET_BIC = "SET_BIC";
export const GET_BANK_BY_IFSC = "GET_BANK_BY_IFSC";
export const RESET_IFSC_DETAILS = "RESET_IFSC_DETAILS";

export const SET_AIRTIME_PRODUCT = "SET_AIRTIME_PRODUCT";
export const SET_QUERY_BY_CODE_PAYLOAD = "SET_QUERY_BY_CODE_PAYLOAD";
export const SET_RECEIPT_DATA = "SET_RECEIPT_DATA";
export const PROCEED_TRANSACTION = "PROCEED_TRANSACTION";
export const COMPLETE_TRANSACTION_WITHOUT_DOCS =
  "COMPLETE_TRANSACTION_WITHOUT_DOCS";

export const SET_TRANSACTION_START_TIME = "SET_TRANSACTION_START_TIME";

export const VERIFY_AND_SUBMIT_TRANSACTION = "VERIFY_AND_SUBMIT_TRANSACTION";
export const PRINT_RECEIPT = "PRINT_RECEIPT";

export const SET_RESEND_DATA = "SET_RESEND_DATA";
export const SET_SPLIT_TRANSACTION = "SET_SPLIT_TRANSACTION";

const GET_SOURCE_CURRENCIES = "GET_SOURCE_CURRENCIES";
const GET_DESTINATION_CURRENCIES = "GET_DESTINATION_CURRENCIES";
const GET_PRICE = "GET_PRICE";
const GET_BANKS_BY_COUNTRY = "GET_BANKS_BY_COUNTRY";
const GET_MOBILE_OPERATORS_BY_COUNTRY = "GET_MOBILE_OPERATORS_BY_COUNTRY";
const GET_MOBILE_OPERATOR_BY_PHONE_NUMBER =
  "GET_MOBILE_OPERATOR_BY_PHONE_NUMBER";

const GET_BANK_BRANCHES = "GET_BANK_BRANCHES";
const GET_PICKUP_LOCATIONS = "GET_PICKUP_LOCATIONS";
const CREATE_NEW_TRANSACTION = "CREATE_NEW_TRANSACTION";
const GET_BANK_ACCOUNT_DETAILS = "GET_BANK_ACCOUNT_DETAILS";
const GET_TRANSACTION_BY_QUERY_CODE = "GET_TRANSACTION_BY_QUERY_CODE";
const GET_CELLPHONE_PRODUCTS = "GET_CELLPHONE_PRODUCTS";
const VERIFY_PROMOCODE = "VERIFY_PROMOCODE_ENDPOINT";
const GET_PROCESSOR_BANKS = "GET_PROCESSOR_BANKS";
const CHECK_COMPLIANCE = "CHECK_COMPLIANCE";
const GET_CITIES_BY_COUNTRY = "GET_CITIES_BY_COUNTRY";
const GET_BRANCHES_BY_CITY = "GET_BRANCHES_BY_CITY";

export const getCitiesByCountryTypes = createConstants(GET_CITIES_BY_COUNTRY);
export const getBranchesByCityTypes = createConstants(GET_BRANCHES_BY_CITY);
export const getBanksByCountryTypes = createConstants(GET_BANKS_BY_COUNTRY);
export const getBankByIfscTypes = createConstants(GET_BANK_BY_IFSC);
export const getMobileOperatorsByCountryTypes = createConstants(
  GET_MOBILE_OPERATORS_BY_COUNTRY,
);
export const getMobileOperatorByPhoneNumber = createConstants(
  GET_MOBILE_OPERATOR_BY_PHONE_NUMBER,
);
export const getBankBranchesTypes = createConstants(GET_BANK_BRANCHES);
export const getSourceCurrenciesTypes = createConstants(GET_SOURCE_CURRENCIES);
export const getDestinationCurrenciesTypes = createConstants(
  GET_DESTINATION_CURRENCIES,
);
export const createNewTransactionTypes = createConstants(
  CREATE_NEW_TRANSACTION,
);
export const getPriceTypes = createConstants(GET_PRICE);
export const getPickupLocationsTypes = createConstants(GET_PICKUP_LOCATIONS);
export const getBankAccountDetailsTypes = createConstants(
  GET_BANK_ACCOUNT_DETAILS,
);

export const getTransactionByQueryCodeTypes = createConstants(
  GET_TRANSACTION_BY_QUERY_CODE,
);

export const verifyPromocodeTypes = createConstants(VERIFY_PROMOCODE);
export const getCellphoneProductsTypes = createConstants(
  GET_CELLPHONE_PRODUCTS,
);
export const getProcessorBanksTypes = createConstants(GET_PROCESSOR_BANKS);
export const checkComplianceTypes = createConstants(CHECK_COMPLIANCE);

export const DUPLICATED_TRANSACTION_INTERVAL_IN_MINUTES = 1440;
// endpoints
export const GET_SOURCE_CURRENCIES_ENDPOINT =
  "/unAuth/organization/getSourceCurrencies";
export const GET_DESTINATION_CURRENCIES_ENDPOINT =
  "/unAuth/organization/getDestinationCurrencies";
export const GET_PRICE_ENDPOINT = "/unAuth/price/getPrice";
export const GET_BANKS_BY_COUNTRY_ENDPOINT = "/operator/getBanksByCountry";
export const GET_MOBILE_OPERATORS_BY_COUNTRY_ENDPOINT =
  "operator/getMobileOperatorsByCountry";
export const GET_MOBILE_OPERATOR_BY_PHONE_NUMBER_ENDPOINT =
  "operator/checkNetworkNumber";
export const GET_BANK_BRANCHES_ENDPOINT = "/operator/getBankBranchesByCountry";
export const GET_PICKUP_LOCATIONS_ENDPOINT = "/operator/getCashPickupByCountry";
export const CREATE_NEW_TRANSACTION_ENDPOINT = "/transactionNew/new";
export const GET_BANK_ACCOUNT_DETAILS_ENDPOINT = "/organization/bankAccount";
export const GET_MSK_ENDPOINT = "/transactionNew/getMsk";
export const CONFIRM_TRANSACTION_ENDPOINT = "/transactionNew/confirm";
export const GET_TRANSACTION_BY_QUERY_CODE_ENDPOINT =
  "/transactionNew/queryByCode";
export const GET_PROCESSOR_BANKS_ENDPOINT = "/transactionNew/processorBanks";
export const CHECK_COMPLIANCE_ENDPOINT = "/transactionNew/checkCompliance";
export const GET_BANK_BY_IFSC_ENDPOINT = "/transaction/queryIFSC";
export const VERIFY_PROMOCODE_ENDPOINT = "campaign/verifyPromoCode";
export const GET_CELLPHONE_PRODUCTS_ENDPOINT =
  "operator/getCellphoneOperatorProducts";
export const GET_CELLPHONE_PRODUCTS_UNAUTH_ENDPOINT =
  "unAuth/getCellphoneOperatorProducts";
export const GET_CITIES_BY_COUNTRY_ENDPOINT = "operator/getCountryCities";
export const GET_BRANCHES_BY_CITY_ENDPOINT =
  "operator/getCashPickupLocationsByCity";

// 3d secure SPcallbackUrls
export const SECURE_3D_SPC_CB_COMMON_ENDPOINT = `${environment.apiBase}/unAuth/callback/spCallBack`;
export const SECURE_3D_SPC_CB_SECURE_TRADING_ENDPOINT = `${environment.apiBase}/unAuth/callback/st3dCallback`;

export enum TransferDetailsConstants {
  SOURCE_AMOUNT = "sourceAmount",
  DESTINATION_AMOUNT = "destinationAmount",
  SOURCE_CURRENCY = "sourceCurrency",
  DESTINATION_CURRENCY = "destinationCurrency",
  PAYOUT_TYPE = "payoutType",
  FUNDING_SOURCE = "fundingSource",
  SOURCE_COUNTRY = "sourceCountry",
  DESTINATION_COUNTRY = "destinationCountry",
  TRANSFER_PRICE = "priceResponse",
  TRANSFER_PRICE_ERROR = "priceResponseError",
  TRANSFER_RECIPIENT = "recipient",
  AIR_TIME_PHONE = "airTimePhone",
  CARD_NUMBER = "cardNumber",
  SOURCE_OF_FUNDS = "sourceOfFunds",
  PURPOSE_OF_TRANSFER = "useOfFunds",
  BANKS_BY_COUNTRY = "banks",
  CITIES_BY_COUNTRY = "cities",
  BRANCHES_BY_CITY = "branchesByCity",
  PROCESSOR_BANKS = "processorBanks",
  MOBILE_OPERATORS_BY_COUNTRY = "mobileOperators",
  MOBILE_OPERATOR_BY_PHONE_NUMBER = "mobileOperator",
  CELLPHONE_PRODUCTS = "cellphoneProducts",
  AIR_TIME_PRODUCT = "airTimeProduct",
  BANK_BRANCHES = "bankBranches",
  PICKUP_LOCATIONS = "pickupLocations",
  BANK_EXTERNAL_CODE = "selectedBankExternalCode",
  PICKUP_LOCATION = "selectedPickupLocation",
  MOBILE_OPERATOR = "mobileOperator",
  TRANSACTION_NEW_RESPONSE = "transactionNewResponse",
  REVERSE_CALCULATION = "reverse",
  BANK_ACCOUNT_TRANSFER_INFO = "bankAccountTransferInfo",
  CREDIT_CARD_DETAILS = "creditCardDetails",
  SECURE_3D_INFO = "secure3dInfo",
  TRANSACTION_DATA_BY_QUERY_CODE = "transactionDataByQueryCode",
  CASH_CODE = "cashCode",
  ACTIVE_COMPLIANCE_RULE = "activeComplianceRules",
  PROMO_CODE = "promoCode",
  OPERATOR_ID = "operatorId",
  IDEAL_BANK_NAME = "idealBanlName",
  BIC = "bic",
  QUERY_BY_CODE_PAYLOAD = "queryByCodePayload",
  RECEIPT_DATA = "receiptData",
  IFSC_BANK_DETAILS = "ifscBankDetails",
  IS_SPLIT_TRANSACTION = "isSplitTransaction",
  COMPLIANCE_CHECK = "complianceCheck",
  TRANSACTION_START_TIME = "transactionStartTime",
}

export enum FundingSources {
  CREDIT_CARD = "CREDIT_CARD",
  CASH = "CASH",
  PAYOSU = "PAYOSU",
  BANK_TRANSFER = "BANK_TRANSFER",
  CHECKOUT_CREDIT_CARD = "CHECKOUT_CREDIT_CARD",
  IDEAL = "IDEAL",
  GOOGLE_PAY = "GOOGLE_PAY",
  BARCLAY_CREDIT_CARD = "BARCLAY_CREDIT_CARD",
}

export const fundingSourcesNames: { [prop: string]: string } = {
  CREDIT_CARD: "Credit/Debit Card",
  CASH: "Cash",
  BANK_TRANSFER: "Bank Transfer",
  CHECKOUT_CREDIT_CARD: "Credit/Debit Card",
  PAYOSU: "Bank Account",
  IDEAL: "iDEAL",
};

export const PayoutTypesNames: {
  [prop: string]: { name: string; icon: string };
} = {
  AIR_TIME: {
    name: "Air Time",
    icon: "air_time",
  },
  CASH: {
    name: "Cash Pickup",
    icon: "cash",
  },
  CASH_TO_CREDIT: {
    name: "Cash to Card",
    icon: "cash",
  },
  CASH_BDO: {
    name: "Cash Pickup (BDO, Palawan, RD)",
    icon: "cash",
  },
  CASH_MLLHUILER: { name: "Cash Pickup (Mlhuillier)", icon: "cash" },
  CASH_CEBUANA: { name: "Cash Pickup (Cebuana)", icon: "cash" },
  BANK_ACCOUNT: { name: "Bank Deposit", icon: "bank_account" },
  BANK_ACCOUNT_SEPA: { name: "Bank Deposit (Sepa)", icon: "bank_account_sepa" },
  BANK_ACCOUNT_BDO: { name: "Bank Deposit (BDO)", icon: "bank_account" },
  BANK_ACCOUNT_OTHER: {
    name: "Bank Deposit(Other banks",
    icon: "bank_account",
  },
  E_ZWITCH: { name: "E-zwitch", icon: "e_zwitch" },
  MOBILE_TOPUP: { name: "Mobile Money", icon: "mobile_topup" },
  BILL_PAYMENT: { name: "Bill Payment", icon: "cash" },
  ONLINE: { name: "Online", icon: "cash" },
  CASH_CARD: { name: "Cash to Card", icon: "cash" },
  DOOR_TO_DOOR: { name: "Door to Door", icon: "cash" },
  DOOR_TO_DOOR_PROVINCIAL: {
    name: "DTP - Door to Door(Provincial)",
    icon: "cash",
  },
  BANK_ACCOUNT_PROVINCIAL: {
    name: "BTP - Bank Deposit(Provincial)",
    icon: "cash",
  },
  CSB: { name: "CSB - Deposit to Sterling Bank of Asia", icon: "cash" },
  CVE: { name: "CVE - ChinaTrust Visa Reloading", icon: "cash" },
  SVE: { name: "SVE - Sterling Visa Reloading", icon: "cash" },
  SOV: { name: "SOV - Sterling Acct Opening Manila Delivery", icon: "cash" },
  SVP: {
    name: "SVP - Sterling Acct Opening Provincial Delivery",
    icon: "cash",
  },
  SVF: { name: "SVF - Sterling Acct Opening Foreign Delivery", icon: "cash" },
  COL: { name: "COL - COL Financial", icon: "cash" },
  PAGIBIG_PAYMENT: { name: "PGB - Pagibig Payment", icon: "cash" },
  PHILHEALTH_PAYMENT: { name: "PHN - Philhealth Payment", icon: "cash" },
  SOCIAL_SECURITY_SYSTEM: {
    name: "SSS - Social Security System",
    icon: "cash",
  },
  MC$: { name: "MC$ - MM US$ Cash DOOR-TO-DOOR", icon: "cash" },
  OB$: { name: "OB$ - US$ Credit To Other Bank Account", icon: "cash" },
  NP$: { name: "NP$ - US$ Notify And Pay", icon: "cash" },
  SDF: { name: "SDF - Sterling Dollar Foreign", icon: "cash" },
  SVD: { name: "SVD Sterling Acct Reloading USD", icon: "cash" },
  NTF: { name: "NTF - Notify And Pay Pick-Up At Foreign", icon: "cash" },
  MCB_PRI: { name: "MCB - PRI", icon: "cash" },
  MCB_IBFT: { name: "MCB - IBFT", icon: "cash" },
  MCB_LITE: { name: "MCB - LITE", icon: "cash" },
  COMPASS_UNION_PAY: { name: "Compass Union Pay", icon: "cash" },
  MOBILE_WALLET: { name: "Mobile Wallet", icon: "mobile_wallet" },
  INSTANT_CREDIT: { name: "Instant Credit", icon: "cash" },
  JFC: { name: "JFC - Jollibee Food Corp", icon: "cash" },
  ISP: { name: "ISP - IShop", icon: "cash" },

  // vala types
  BANK_ACCOUNT_PUBALI: { name: "Bank Deposit - Pubali Bank", icon: "cash" },
  BANK_ACC_SOUTH_EAST: { name: "Bank Deposit - South East", icon: "cash" },
  BANK_ACCOUNT_PREMIER: { name: "Bank Deposit - Premier Bank", icon: "cash" },
  BANK_ACCOUNT_UTTARA: { name: "Bank Deposit - Uttara Bank", icon: "cash" },
  BANK_ACC_AL_ARAFAH_ISLAMI: {
    name: "Bank Deposit - Al Arafah Islami Bank",
    icon: "cash",
  },
  CASH_PREMIER: { name: "Cash Pickup - Premier Bank", icon: "cash" },
  CASH_AL_ARAFAH_ISLAMI: {
    name: "Cash Pickup - Al Arafah Islami Bank",
    icon: "cash",
  },
  CASH_UTTARA: { name: "Cash Pickup - Uttara Bank", icon: "cash" },
  CASH_SOUTH_EAST: { name: "Cash Pickup - South East", icon: "cash" },
  CASH_PUBALI: { name: "Cash Pickup - Pubali Bank", icon: "cash" },
};

export enum PayoutTypesCategories {
  BANK_ACCOUNT = "BANK_ACCOUNT",
  WALLET = "WALLET",
  AIR_TIME = "AIR_TIME",
  CASH_TO_CARD = "CASH_TO_CARD",
  CASH_PICKUP = "CASH_PICKUP",
  CASH = "CASH",
  MOBILE_TOPUP = "MOBILE_TOPUP",
  MOBILE_TOP_UP = "MOBILE_TOP_UP", // Check
}

export enum TransactionOrigins {
  WEB = "WEB",
}

export enum TransactionStatuses {
  PRE_TXN = "PRE_TXN",
  PENDING_FS = "PENDING_FS",
  RISK_APPROVED = "RISK_APPROVED",
  AGENT_NO_BALANCE = "AGENT_NO_BALANCE",
  FS_CHARGED = "FS_CHARGED",
  FS_IN_PROCESS = "FS_IN_PROCESS",
  FS_DEINED = "FS_DEINED",
  FAIL_FS_CHARGED = "FAIL_FS_CHARGED",
  INFO_MISSING = "INFO_MISSING",
  PROCESSING = "PROCESSING",
  COMPLETE_SUCCESS = "COMPLETE_SUCCESS",
  RISK_FAIL = "RISK_FAIL",
  FAIL = "FAIL",
  CANCELLED = "CANCELLED",
  REJECTED = "REJECTED",
  PENDING_CANCELLATION = "PENDING_CANCELLATION",
  REVERSED = "REVERSED",
  REFUNDED = "REFUNDED",
  PENDING_REVIEW = "PENDING_REVIEW",
  TELLER_APPROVED = "TELLER_APPROVED",
  ADMIN_APPROVED = "ADMIN_APPROVED",
  COMPLIANCE_REVIEW = "COMPLIANCE_REVIEW",
  COMPLIANCE_LOCKED = "COMPLIANCE_LOCKED",
  COMPLIANCE_FAILED = "COMPLIANCE_FAILED",
  UNPAID = "UNPAID",
  BLACKLIST_REVIEW = "BLACKLIST_REVIEW",
}

// Compliance rules

export const APPLY_COMPLIANCE_RULE = "APPLY_COMPLIANCE_RULE";
export const CLEAR_COMPLIANCE_RULE = "CLEAR_COMPLIANCE_RULE";
export const COMPLIANCE_RULE_FAILED = "COMPLIANCE_RULE_FAILED";

export enum ComplianceRules {
  BLOCK = "BLOCK",
  REQUIRE_DOC = "REQUIRE_DOC",
  DOCUMENT_MANUAL = "DOCUMENT_MANUAL",
  REQUIRE_DOC_MANUAL = "REQUIRE_DOC_MANUAL",
  MANUAL = "MANUAL",
}

export const ComplianceRulesPriority: { [prop: string]: number } = {
  [ComplianceRules.BLOCK]: 0,
  [ComplianceRules.REQUIRE_DOC]: 1,
  [ComplianceRules.REQUIRE_DOC_MANUAL]: 2,
};

export enum GetPriceFailMessages {
  NO_FEE_FOUND_FOR_ORGANIZATION = "No fee found for organization",
  TRY_GREATER_AMOUNT = "No fee found for organization. Try greater amount",
  TRY_SMALLER_AMOUNT = "No fee found for organization. Try smaller amount",
}

export enum QueryTypes {
  RESERVATION = "RESERVATION",
  DEPOSIT = "DEPOSIT",
  ID = "ID",
}

export enum UserAccountStatuses {
  PENDING_REVIEW = "PENDING_REVIEW",
  FAILED = "FAILED",
}

const {
  BANK_TRANSFER_SUCCESS,
  TRANSACTION_SUCCESS,
  TRANSACTION_UNDER_REVIEW,
  TRANSACTION_FAILED,
  TRANSACTION_CARD_DECLINED,
  COMPLIANCE_REVIEW_REQUIRE_DOC_SCREEN,
  TRANSACTION_CANCELLED,
  ACCOUNT_BLOCKED,
  ACCOUNT_UNDER_REVIEW,
  TRANSACTION_SUBMITTED,
  PENDING_TRANSACTION,
} = moneyTransferRoutes;

export const transactionFlowFinalScreens = [
  BANK_TRANSFER_SUCCESS,
  TRANSACTION_SUCCESS,
  TRANSACTION_UNDER_REVIEW,
  TRANSACTION_FAILED,
  TRANSACTION_CARD_DECLINED,
  COMPLIANCE_REVIEW_REQUIRE_DOC_SCREEN,
  TRANSACTION_CANCELLED,
  ACCOUNT_UNDER_REVIEW,
  ACCOUNT_BLOCKED,
  PENDING_TRANSACTION,
  TRANSACTION_SUBMITTED,
  PENDING_TRANSACTION,
];
